@font-face {
  font-family: "PtopFont";
  font-weight: 300;
  src: local("PtopFont"), url(/fonts/almoni-dl-aaa-300.woff) format("woff");
}

@font-face {
  font-family: "PtopFont";
  font-weight: 400;
  src: local("PtopFont"), url(/fonts/almoni-dl-aaa-400.woff) format("woff");
}

@font-face {
  font-family: "PtopFont";
  font-weight: 700;
  src: local("PtopFont"), url(/fonts/almoni-dl-aaa-700.woff) format("woff");
}

@font-face {
  font-family: "PtopFont";
  font-weight: 900;
  src: local("PtopFont"), url(/fonts/almoni-dl-aaa-900.woff) format("woff");
}

@font-face {
  font-family: "PtopFont";
  font-weight: bold;
  src: local("PtopFont"), url(/fonts/almoni-dl-aaa-900.woff) format("woff");
}

.App .ag-theme-material {
  font-family: "PtopFont";
  color: #61616a;
}

.App .ag-theme-material .ag-header {
  font-family: "PtopFont";
}

.App .ag-theme-material .ag-header-cell-label {
  justify-content: center;
}

.App {
  text-align: right;
  direction: rtl;
  font-family: "PtopFont";
}

.year-grid .ag-theme-material .ag-header-cell {
  padding-right: 0px;
  padding-left: 0px;
  justify-content: center;
}

body {
  direction: rtl;
  font-family: "PtopFont";
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.ag-react-container {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
